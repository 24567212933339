import { Alert, Button, TextField, Typography } from '@mui/material';
// Hooks
import { useTwoFactorAuth } from '@/hooks/useTwoFactorAuth';
import { useCountdown } from '@/hooks/useCountdown';
// Utils
import { brand } from '@/brand/brand';
import { isValidCode } from '@/utils/twoFactorAuth';
// Components
import { AlertBody } from '../common';
// Styles
import { AppBadge, SMSBadge } from '@/assets/icons/iconComponents';
import arrow from '@/icons/fontawesome/arrow-right-solid.svg';
import styles from './twoFactorAuthModal.module.css';
import { getAuthModelValues } from './helper';
import {
  AUTHENTICATOR_CODE_LENGTH,
  EMAIL_MFA_CODE_LENGTH,
} from '../../hooks/useTwoFactorAuth';

export default function TwoFactorAuthModal({ session, appAuth, close, callback }) {
  const {
    authCode,
    authError,
    captchaId,
    handleChangeCode,
    handleResetCode,
    handleSendSMS,
    handleValidateCode,
    isLoading,
    phone,
    setAuthCode,
    setAuthError,
  } = useTwoFactorAuth({ session, appAuth, callback });
  const { counter, resetCounter } = useCountdown();
  const authCodeRegex = new RegExp(`^\\d{${AUTHENTICATOR_CODE_LENGTH}}$`);

  const handleFocus = (e) => {
    const input = e.target;

    if (appAuth.type === 'authenticator' && authCodeRegex.test(input?.value)) {
      const chars = input.value.split('');
      setAuthCode(chars);
      handleValidateCode(input.value);
      return;
    }

    if (!authError.error) return;
    document.querySelector(`input[name=code-0]`).focus();
    handleResetCode();
  };

  const goToNextInput = (currIndex) => {
    const lastIndex = authCode.length - 1;
    // Blur last input on change
    if (currIndex === lastIndex) {
      const lastCode = document.querySelector(`input[name=code-${lastIndex}]`);
      if (lastCode !== '') {
        lastCode.blur();
      }
      return;
    }
    document.querySelector(`input[name=code-${currIndex + 1}]`).focus();
  };

  const handleDelete = (e) => {
    if (e.key !== 'Backspace') return;
    const { id } = e.target;
    if (id !== '0') {
      handleChangeCode('', +id - 1);
      document.querySelector(`input[name=code-${+id - 1}]`).focus();
    }
  };

  const handleChange = (e) => {
    const { value, id } = e.target;

    if (isNaN(+value)) return;

    handleChangeCode(value, +id);
    goToNextInput(+id);
  };

  function handlePaste(e) {
    const clipboardData = e.clipboardData.getData('text/plain');
    const codeArr = clipboardData.split('');

    // Blur input on paste
    document.querySelector(`input[name=code-0]`).blur();

    if (
      !isValidCode(
        codeArr,
        appAuth.type === 'email' ? EMAIL_MFA_CODE_LENGTH : AUTHENTICATOR_CODE_LENGTH
      )
    ) {
      setAuthError({
        error: true,
        header:
          appAuth.appEnabled || appAuth.type === 'email'
            ? 'Invalid Code.'
            : 'Wrong SMS code.',
        message: 'Please try again.',
      });
      e.preventDefault();
      return;
    }

    setAuthCode(codeArr);
    handleValidateCode(clipboardData);
  }

  const handleResendClick = () => {
    handleSendSMS();
    resetCounter();
  };

  const { title, description } = getAuthModelValues(appAuth, phone);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.modalIconContainer}>
          {/* TODO FOR NERD: Add an email icon here */}
          {appAuth.appEnabled || appAuth.type === 'email' ? (
            <AppBadge className={styles.icon} />
          ) : (
            <SMSBadge className={styles.icon} />
          )}
        </div>
        <Typography variant="h3" data-core-qa="loginMFAModalTitleText">
          {title}
        </Typography>
        <Typography
          variant="body1"
          className={styles.subheader}
          data-core-qa="loginMFAModalSubText"
        >
          {description}
        </Typography>
        {authError.error ? (
          <div className={styles.alertContainer}>
            <Alert
              severity="error"
              variant="outlined"
              data-core-qa="loginMFAModalErrorText"
            >
              <AlertBody header={authError.header} body={authError.message} />
            </Alert>
          </div>
        ) : null}
        <div className={styles.codeContainer}>
          {authCode.map((value, i) => (
            <TextField
              key={i}
              id={i}
              name={`code-${i}`}
              error={authError.error}
              value={value}
              onChange={handleChange}
              onFocus={handleFocus}
              onPaste={handlePaste}
              onKeyDown={handleDelete}
              maxLength={1}
              data-core-qa="mfaVerifyCodeInput"
              inputProps={{
                inputMode: 'numeric',
                style: {
                  textAlign: 'center',
                  width: '10px',
                  cursor: 'text',
                },
              }}
            />
          ))}
        </div>
        {isLoading ? (
          <Typography variant="body1" className={styles.loading}>
            Verifying...
          </Typography>
        ) : appAuth.appEnabled || appAuth.type === 'email' ? null : (
          <div className={styles.resendRow}>
            {counter ? (
              <Typography variant="body1">
                Retry sending code in
                <span data-core-qa="mfaVerifyCodeCountDown">{` ${counter}s`}</span>
              </Typography>
            ) : (
              <>
                <Typography variant="body1">Didn&apos;t get a code? </Typography>
                <Button
                  type="button"
                  variant="text"
                  onClick={handleResendClick}
                  data-core-qa="mfaVerifyCodeLink"
                  disabled={counter || isLoading}
                  sx={{
                    paddingLeft: 0,
                  }}
                >
                  Click to resend.
                  <img src={arrow.src} alt="arrow" className={styles.arrowIcon} />
                </Button>
              </>
            )}
          </div>
        )}
        {appAuth.appEnabled || appAuth.type === 'email' ? null : (
          <Typography
            variant="body1"
            className={styles.subtext}
            data-core-qa="loginMFAModalSupportText"
          >
            If you have issues with receiving a code on your phone and are unable to
            proceed, please contact
            <a href={brand.staticSite} style={{ textDecoration: 'underline' }}>
              {` ${brand.brandName} customer service.`}
            </a>
          </Typography>
        )}
        <Button
          data-core-qa="mfaVerifyCodeButtonCancel"
          type="button"
          size="large"
          variant="outlined"
          onClick={close}
          fullWidth
        >
          Cancel
        </Button>
      </div>
      {/* recaptcha verifier div is required and must be empty - do not remove*/}
      <div id={captchaId} data-core-qa="loginRecaptcha"></div>
    </>
  );
}
