export const isValidCode = (codeArr, length = 6) =>
  Array.isArray(codeArr) &&
  !codeArr?.some((num) => num === '' || isNaN(+num)) &&
  codeArr.length === length;

export const encodePhone = (phone) =>
  phone?.replace(/(\W{1})(\W{1})(\W{3})(\W{3})(\d{2})(\d{2})/, '$1 x (xxx) xxx-xx$6');

export const formatPhone = (phone) => {
  if (phone.charAt(1) === '1') {
    return phone?.replace(/(\W{1})(\d{1})(\d{3})(\d{3})(\d{4})/, '$1 $2 ($3) $4-$5');
  } else {
    return phone;
  }
};
