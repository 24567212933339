import { useEffect, useState } from 'react';
// Hooks
import { useAuth } from './useAuth';
import * as auth from '@nerdcoresdk/nerd-core-auth';
// Utils
import { encodePhone, isValidCode } from '@/utils/twoFactorAuth';

export const AUTHENTICATOR_CODE_LENGTH = 6;
export const EMAIL_MFA_CODE_LENGTH = 5;
const CAPTCHA_ID = 'recaptchaDiv';
const TOO_MANY_ATTEMPTS =
  'Your account has been temporarily disabled due to too many attempts. Please try again later.';
const INVALID_CODE = 'Please try again.';
const GENERIC_ERROR = 'Something has gone wrong, please try again later.';
const QUOTA_EXCEEDED = 'SMS Quota Exceeded. Please try again later.';

const initAuthCode = (codeLength) => Array.from({ length: codeLength }, () => '');
const initError = {
  error: false,
  header: '',
  message: '',
};

export const useTwoFactorAuth = ({ session, appAuth, callback }) => {
  const { createRecaptcha, enrichToken, sendLoginMFA, resetPassword } = useAuth();
  const { validateMFACode, authAppSignIn } = auth.getInstance();

  const authCodeLength =
    appAuth.type === 'email' ? EMAIL_MFA_CODE_LENGTH : AUTHENTICATOR_CODE_LENGTH;

  const [isLoading, setIsLoading] = useState(false);
  const [authCode, setAuthCode] = useState(initAuthCode(authCodeLength));
  const [authError, setAuthError] = useState(initError);
  const [isCodeReady, setIsCodeReady] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState(null);
  const [phone, setPhone] = useState('');
  const [sessionData, setSessionData] = useState(null);
  const [newPassword, setNewPassword] = useState('');

  useEffect(() => {
    if (!session?.resolver) return;
    setPhone(encodePhone(session.phone));
    setSessionData(session.resolver);
    if (session?.password) setNewPassword(session.password);

    createRecaptcha(CAPTCHA_ID)
      .then(setRecaptchaVerifier)
      .catch((e) => console.error(e));
  }, [session]);

  useEffect(() => {
    if (!recaptchaVerifier) return;
    handleSendSMS();
  }, [recaptchaVerifier]);

  useEffect(() => {
    if (!isCodeReady) return;
    const codeString = authCode.join('');
    handleValidateCode(codeString);
  }, [isCodeReady]);

  const handleSendSMS = () =>
    sendLoginMFA({ sessionData, recaptchaVerifier })
      .then(setVerificationId)
      .catch(handleCatchError);

  const handleValidateCode = (verificationCode) => {
    setIsLoading(true);
    // external all authenticate code flow
    if (appAuth.appEnabled) {
      const token = appAuth.userToken;
      return authAppSignIn({ token, verificationCode, method: 'GET' })
        .then(callback)
        .catch((err) => {
          if (err?.error?.ErrorDetail.includes('Invalid Authenticator Pin')) {
            return setAuthError({
              error: true,
              header: appAuth.appEnabled ? 'Invalid Code.' : 'Wrong SMS code.',
              message: INVALID_CODE,
            });
          } else if (err?.error?.ErrorDetail.includes('Invalid Email Pin')) {
            return setAuthError({
              error: true,
              header: 'Invalid Code.',
              message: INVALID_CODE,
            });
          } else {
            setAuthError({
              error: true,
              header: 'System Error.',
              message: GENERIC_ERROR,
            });
          }
        })
        .finally(() => setIsLoading(false));
    }
    // SMS code validation flow
    const handleEnrichToken = (token) =>
      enrichToken({ token, method: 'GET' }).then(callback);

    validateMFACode({
      verificationId,
      verificationCode,
      sessionData,
      method: 'GET',
    })
      .then(({ user }) => {
        if (newPassword) {
          resetPassword({ user, password: newPassword, method: "GET'" })
            .then(() => handleEnrichToken(user.accessToken))
            .catch((e) => console.error(e));
        } else {
          handleEnrichToken(user.accessToken);
        }
      })
      .catch(handleCatchError);
  };

  const handleChangeCode = (value, index) => {
    const newCode = [...authCode.slice(0, index), value, ...authCode.slice(index + 1)];
    setAuthCode(newCode);
    // Trigger useEffect to validate code
    if (
      isValidCode(
        newCode,
        appAuth.type === 'email' ? EMAIL_MFA_CODE_LENGTH : AUTHENTICATOR_CODE_LENGTH
      )
    )
      setIsCodeReady(true);
  };

  const handleResetCode = () => {
    setIsCodeReady(false);
    setAuthCode(initAuthCode(authCodeLength));
    setAuthError(initError);
  };

  const handleCatchError = (e) => {
    setIsLoading(false);
    if (e?.code === 'auth/quota-exceeded')
      return setAuthError({
        error: true,
        header: 'Account Locked.',
        message: QUOTA_EXCEEDED,
      });
    if (e?.code === 'auth/invalid-verification-code')
      return setAuthError({
        error: true,
        header: appAuth.appEnabled ? 'Invalid Code.' : 'Wrong SMS code.',
        message: INVALID_CODE,
      });
    if (e?.message.includes('TOO_MANY_ATTEMPTS'))
      return setAuthError({
        error: true,
        header: 'Account Locked.',
        message: TOO_MANY_ATTEMPTS,
      });

    setAuthError({
      error: true,
      header: 'System Error.',
      message: GENERIC_ERROR,
    });
  };

  return {
    authCode,
    authError,
    captchaId: CAPTCHA_ID,
    handleChangeCode,
    handleResetCode,
    handleSendSMS,
    handleValidateCode,
    isLoading,
    phone,
    setAuthCode,
    setAuthError,
  };
};
