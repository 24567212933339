import {
  AUTHENTICATOR_CODE_LENGTH,
  EMAIL_MFA_CODE_LENGTH,
} from '../../hooks/useTwoFactorAuth';

export const getAuthModelValues = (appAuth, phone) => {
  switch (appAuth.type) {
    case 'authenticator':
      return {
        title: 'Enter Two-Factor Authentication',
        description: `Enter the ${AUTHENTICATOR_CODE_LENGTH}-digit verification code from your authenticator app below.`,
      };
    case 'email':
      return {
        title: 'Enter Email Code',
        description: `Enter the ${EMAIL_MFA_CODE_LENGTH}-digit verification code that was sent to your email. If you haven’t received an email, cancel and try logging in again.`,
      };
    default:
      return {
        title: 'Enter Two-Factor Authentication',
        description: `Enter the ${AUTHENTICATOR_CODE_LENGTH}-digit authentication code below we sent to ${phone}`,
      };
  }
};
