import { useEffect, useState } from 'react';

export const useCountdown = (time = 30) => {
  const [counter, setCounter] = useState(time);
  const resetCounter = () => setCounter(time);

  useEffect(() => {
    let timer;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((prev) => prev - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  return { counter, resetCounter };
};
